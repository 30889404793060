import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";

class LineChart extends React.Component {
  render() {
    const { data, indexBy, axisBottomLegend, axisLeftLegend } = this.props;

    const values = _.map(data[0].data, (item) => {
      let date = new Date(item.x);
      return [
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
        item.y,
      ];
    });

    const options = {
      chart: {
        type: "spline",
        zoomType: "x",
      },
      xAxis: {
        crosshair: true,
        type: "datetime",
        title: {
          text: axisBottomLegend,
        },
        colors: ["#111"],
        labels: {
          formatter: (item) => {
            const date = new Date(item.value);
            const string = date.toDateString();
            return string.split(" ").slice(1, 3).join(" ");
          },
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        spline: {
          color: "#111",
          marker: {
            enabled: true,
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: `${axisLeftLegend}`,
        },
      },
      series: [
        {
          name: data[0].id,
          data: values,
        },
      ],
      legend: {
        enabled: false,
      },
      title: {
        text: "",
        floating: true,
      },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}

LineChart.propTypes = {
  data: PropTypes.array,
};

export default LineChart;
