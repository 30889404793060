import React from "react";
import PropTypes from "prop-types";
import { ResponsivePie } from "@nivo/pie";

class PieChart extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <React.Fragment>
        <ResponsivePie
          data={data}
          innerRadius={0}
          colors={["#ddd", "#888", "#000"]}
          sortByValue={true}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          activeInnerRadiusOffset={40}
          activeOuterRadiusOffset={20}
          margin={{ top: 20, right: 10, bottom: 20, left: 10 }}
          onClick={(e) => {
            window.open(`https://etherscan.io/address/${e.id}`);
          }}
          tooltip={({ datum: { id, value, color } }) => (
            <div
              style={{
                padding: 12,
                color: "#fff",
                background: "#222222",
              }}
            >
              <strong>
                <a href="https://etherscan.io/address/{id}" target="_blank">
                  {id.substring(0, 6)}...{id.slice(-6)}
                </a>
                : {parseFloat(value).toFixed(2)}%
              </strong>
            </div>
          )}
        />
      </React.Fragment>
    );
  }
}

export default PieChart;
