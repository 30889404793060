import React from "react";
import PropTypes from "prop-types";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";

class ScatterChart extends React.Component {
  render() {
    const { data, axisLeftLegend, axisBottomLegend } = this.props;
    const isSunday = (value) => {
      const date = new Date(value);
      try {
        return date.getDay() === 1;
      } catch (e) {
        return false;
      }
    };

    const formatter = (value) => {
      const date = new Date(value);
      const string = date.toDateString();
      return string.split(" ").slice(1, 3).join(" ");
    };

    return (
      <React.Fragment>
        <ResponsiveScatterPlot
          data={data}
          margin={{ top: 10, right: 20, bottom: 60, left: 50 }}
          padding={0.1}
          colors="greys"
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            precision: "day",
          }}
          xFormat="time:%Y-%m-%d"
          axisBottom={{
            format: function (value) {
              return isSunday(value) ? formatter(value) : "";
            },
            format: "%b %d",
            tickValues: "every 7 days",
            tickSize: 5,
            tickPadding: 8,
            tickRotation: -45,
            legend: `${axisBottomLegend}`,
            legendPosition: "middle",
            legendOffset: 52,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: `${axisLeftLegend}`,
            legendPosition: "middle",
            legendOffset: -40,
          }}
        />
      </React.Fragment>
    );
  }
}

export default ScatterChart;
