
import "controllers";
import "stylesheets/stats.scss";
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://e7cae43300234c36b84c6bfdcf20c2cb@o1036852.ingest.sentry.io/6089350"
});

import Rails from "@rails/ujs";
Rails.start();

import jQuery from "jquery";

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
