import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";

class BarChart extends React.Component {
  render() {
    const { data, indexBy, axisBottomLegend, axisLeftLegend } = this.props;
    const values = _.map(data, (item) => item.value);

    const options = {
      chart: {
        type: "column",
        zoomType: "x",
      },
      xAxis: {
        categories: _.map(data, (item) => item.date),
        crosshair: true,
        type: "datetime",
        title: {
          text: axisBottomLegend,
        },
        colors: ["#111"],
        labels: {
          formatter: (item) => {
            const date = new Date(item.value);
            const string = date.toDateString();
            return string.split(" ").slice(1, 3).join(" ");
          },
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          color: "#1a1a1a",
          groupPadding: 0,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: `${axisLeftLegend}`,
        },
      },
      series: [
        {
          name: axisLeftLegend,
          data: values,
        },
      ],
      legend: {
        enabled: false,
      },
      title: {
        text: "",
        floating: true,
      },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}

BarChart.propTypes = {
  data: PropTypes.array,
};

export default BarChart;
