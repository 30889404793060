import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";

class CombinedChart extends React.Component {
  render() {
    const {
      lineSeries,
      columnSeries,
      axisBottomLegend,
      axisRightLegend,
      axisLeftLegend,
      axisLeftSuffix,
      axisRightSuffix,
    } = this.props;

    const lineValues = _.map(lineSeries[0].data, (item) => {
      let date = new Date(item.x);
      return [
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
        item.y,
      ];
    });

    const values = _.map(lineValues, (item) => item[1]);
    const lineMax = _.max(values) * 1.25;

    const columnValues = _.map(columnSeries, (item) => {
      let date = new Date(item.date);
      return {
        x: Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
        y: item.value,
      };
    });

    const options = {
      chart: {
        zoomType: "x",
      },
      xAxis: {
        crosshair: true,
        type: "datetime",
        title: {
          text: axisBottomLegend,
        },
        colors: ["#111"],
        labels: {
          formatter: (item) => {
            const date = new Date(item.value);
            const string = date.toDateString();
            return string.split(" ").slice(1, 3).join(" ");
          },
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        spline: {
          color: "#1a1a1a",
          marker: {
            enabled: true,
          },
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        column: {
          color: "#a1a1a1",
          groupPadding: 0,
        },
      },
      yAxis: [
        {
          min: 0,
          title: {
            text: `${axisLeftLegend}`,
          },
        },
        {
          min: 0,
          gridLineWidth: 0,
          title: {
            text: `${axisRightLegend}`,
          },
          max: lineMax,
          opposite: true,
        },
      ],
      tooltip: {
        shared: true,
      },
      series: [
        {
          type: "column",
          name: axisLeftLegend,
          data: columnValues,
          tooltip: {
            valueSuffix: ` ${axisLeftSuffix}`,
          },
        },
        {
          name: lineSeries[0].id,
          data: lineValues,
          yAxis: 1,
          type: "spline",
          tooltip: {
            valueSuffix: ` ${axisRightSuffix}`,
          },
        },
      ],
      legend: {
        enabled: false,
      },
      title: {
        text: "",
        floating: true,
      },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}

CombinedChart.propTypes = {
  data: PropTypes.array,
};

export default CombinedChart;
